import React from 'react'
import { AppBar, makeStyles, Hidden } from '@material-ui/core'
import AppBarWeb from './AppBarWeb'
import AppBarMobile from './AppBarMobile'
import generalCss from '../../styles/generalCss'


const useStyles = makeStyles(theme => ({
    appBarRoot: {
        zIndex: theme.zIndex.drawer + 101,
        backgroundColor: "white",
        [theme.breakpoints.up('md')]: {
            zIndex: theme.zIndex.appBar,
            backgroundColor: generalCss.primaryFadedBgColor
        }
    }
}))

export default function Navbar({ openDrawer, setOpenDrawer, appBarData = [], contactData = {} }) {
    const classes = useStyles()
    return (
        <AppBar className={classes.appBarRoot} elevation={0}>
            <Hidden smDown implementation='css'>
                <AppBarWeb appBarData={appBarData} contactData={contactData} />
            </Hidden>
            <Hidden mdUp implementation='css'>
                <AppBarMobile openDrawer={openDrawer} appBarData={appBarData} setOpenDrawer={setOpenDrawer} />
            </Hidden>
        </AppBar>
    )
}
