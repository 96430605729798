const Routes = {
    home: "/",
    about: '/about',
    solutions: "/solutions",
    portfolio: "/projects",
    caseStudies: "/case-studies",
    products: "/products",
    contact: "/contact"
}

export default Routes