import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { primaryButtonSVGbase64Url, primaryButtonHoverSVGbase64Url } from '../../../utilities/Configurations'
const useStyles = makeStyles(theme => ({
    root: {
        backgroundImage: `url("${primaryButtonSVGbase64Url}")`,
        borderRadius: '0px',
        padding: '0px',
        textTransform: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "100% 100%",
        color: "white",
        fontSize: '1rem',
        lineHeight: "1.25rem",
        fontWeight: "600",
        minWidth: "180px",
        [theme.breakpoints.down('xs')]: {
            minWidth: "100%",
        },
        '&:hover': {
            backgroundImage: `url("${primaryButtonHoverSVGbase64Url}")`,
            backgroundColor: 'transparent'
        }
    },
    labelStyles: {
        padding: "15px",
        textAlign: 'center'
    }
}))

export default function PrimaryButton({ children, className, ...props }) {
    const classes = useStyles()
    return (
        <Button classes={{
            label: classes.labelStyles
        }} className={clsx(classes.root, className)} fullWidth={false}  {...props}>
            {children}
        </Button>
    )
}
