export const returnTitleSubHeadingsAndImages = (data) => {
    let subHeadings = []
    if (!!data.SubHeading1) {
        subHeadings.push({
            id: 'sub-heading1',
            value: data.SubHeading1,
        })
    }
    if (!!data.subHeading) {
        subHeadings.push({
            id: 'sub-heading1',
            value: data.subHeading,
        })
    }
    if (!!data.SubHeading2) {
        subHeadings.push({
            id: 'sub-heading2',
            value: data.SubHeading2,
        })
    }
    if (!!data.subheading2) {
        subHeadings.push({
            id: 'sub-heading2',
            value: data.subheading2,
        })
    }
    return {
        title: !!data.heading ? data.heading : !!data.title ? data.title : !!data.Heading1 ? data.Heading1 : !!data.projectTitle ? data.projectTitle : '',
        subHeadings: subHeadings,
        subHeading: !!data.subHeading ? data.subHeading : !!data.excerpt ? data.excerpt : "",
        subTitle: !!data.subTitle ? data.subTitle : !!data.subtitle ? data.subtitle : '',
        coverImageMobile: !!data.coverImageMobile ? data.coverImageMobile : { url: "", alternativeText: '' },
        coverImageWeb: !!data.coverImageWeb ? data.coverImageWeb : { url: "", alternativeText: '' },
        coverImage: !!data.coverImage ? data.coverImage : !!data.projectCoverImage ? data.projectCoverImage : { url: "", alternativeText: "" }
    }
}

export const arraySorter = ({ array, checkKey, isNumberValue }) => {
    let newArray = []
    if (isNumberValue) {
        newArray = array.sort((a, b) => a[checkKey] - b[checkKey])
    } else {
        newArray = array.sort((a, b) => a[checkKey] > b[checkKey] ? 1 : b[checkKey] > a[checkKey] ? 1 : 0)
    }
    return newArray
}

export const seoValuesReturn = (object) => {
    const seoTitle = !!object.SEO_TITLE ? object.SEO_TITLE : ''
    const seoDescription = !!object.SEO_DESCRIPTION ? object.SEO_DESCRIPTION : ''
    const seoSchemaTag = !!object.SCHEMA_TAGS ? object.SCHEMA_TAGS : []
    return { title: seoTitle, description: seoDescription, schemaTag: seoSchemaTag }
}