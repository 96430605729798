import { createTheme } from "@material-ui/core";
import { paletteConfig } from '../utilities/Configurations'

const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200
    }
}

let Theme = createTheme({
    palette: {
        ...paletteConfig
    },
    breakpoints: breakpoints,
    typography: {
        fontFamily: '"Montserrat", sans-serif',
        h1: {
            fontSize: '1.8rem',
            fontWeight: '600',
            lineHeight: "2.3rem",
            [createTheme({ breakpoints }).breakpoints.up('sm')]: {
                fontSize: '2.25rem',
                fontWeight: 'bold',
                lineHeight: "3.2rem",
            }
        },
        h2: {
            fontSize: '1.8rem',
            fontWeight: '600',
            lineHeight: "2.3rem",
            [createTheme({ breakpoints }).breakpoints.up('sm')]: {
                fontSize: '2.25rem',
                fontWeight: 'bold',
                lineHeight: "3.2rem"
            }
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: '600',
            lineHeight: "2rem",
            [createTheme({ breakpoints }).breakpoints.up('sm')]: {
                fontSize: '2rem',
                fontWeight: '600',
                lineHeight: "2.8rem",
            }
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: '600',
            lineHeight: "2rem",
            [createTheme({ breakpoints }).breakpoints.up('sm')]: {
                fontSize: '2rem',
                fontWeight: '600',
                lineHeight: "2.8rem",
            }
        },
        h5: {
            fontSize: '1.125rem',
            fontWeight: '600',
            lineHeight: "1.8rem",
            [createTheme({ breakpoints }).breakpoints.up('sm')]: {
                fontSize: '1.25rem',
                fontWeight: '600',
                lineHeight: "2rem",
            }
        },
        h6: {
            fontSize: '1.125rem',
            fontWeight: '600',
            lineHeight: "1.8rem",
            [createTheme({ breakpoints }).breakpoints.up('sm')]: {
                fontSize: '1.25rem',
                fontWeight: '600',
                lineHeight: "2rem",
            }
        },
        body1: {
            fontSize: '1rem',
            lineHeight: "1.5rem",
            fontWeight: 'normal',
            [createTheme({ breakpoints }).breakpoints.up('sm')]: {
                fontSize: '1.125rem',
                lineHeight: "2rem",
                fontWeight: '500',
            }

        },
        body2: {
            fontSize: '0.75rem',
            lineHeight: "0.875rem",
            [createTheme({ breakpoints }).breakpoints.up('sm')]: {
                fontSize: '0.875rem',
                lineHeight: "1.5rem",
            }
        }
    },
    overrides: {
        MuiTimelineConnector: {
            root: {
                borderLeft: '1px dashed #263B50',
                backgroundColor: 'transparent',
                width: "0px",
                minHeight: "100px"
            }
        },
        MuiTimelineDot: {
            root: {
                boxShadow: 'none',
                backgroundColor: 'transparent !important',
                border: 'none',
                borderRadius: "0px",
                margin: "0px !important",
                marginTop: "0px !important",
                marginBottom: "0px !important",
                padding: "0px !important"
            }
        }
    }
})

export default Theme