import React from 'react'
import { IconButton } from '@material-ui/core'

export default function IconButtonComponent({ children, ...props }) {
    return (
        <IconButton style={{ padding: "0px" }} {...props}>
            {children}
        </IconButton>
    )
}
