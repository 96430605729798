import React from 'react'
import { Link } from 'gatsby'

export default function LinkComponent({ path, children, className, activeClassName, appendPath = true }) {
    return (
        <Link to={appendPath ? path === '/' ? path : `${path}/` : path} style={{ textDecoration: 'none' }} activeClassName={activeClassName} className={className}
        >
            {children}
        </Link>
    )
}
