import React from 'react'

export default function LinkedIn() {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.6991 1.31626C2.6991 2.03384 2.16319 2.61456 1.32512 2.61456C0.519699 2.61456 -0.0162109 2.03384 0.000374364 1.31626C-0.0162109 0.56381 0.519687 0 1.34117 0C2.16318 0 2.68304 0.56381 2.6991 1.31626ZM0.0677544 11.9986V3.6402H2.61565V11.998H0.0677544V11.9986Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.65783 6.30806C4.65783 5.2655 4.62414 4.37671 4.59045 3.64169H6.80354L6.92118 4.78676H6.97147C7.3068 4.25676 8.14487 3.4541 9.50279 3.4541C11.1789 3.4541 12.4363 4.58226 12.4363 7.04255V12.0001H9.88839V7.36806C9.88839 6.29062 9.51989 5.55613 8.59786 5.55613C7.8935 5.55613 7.47473 6.05178 7.30732 6.52999C7.23995 6.7012 7.20677 6.94004 7.20677 7.17994V12.0001H4.65886V6.30806H4.65783Z" fill="white" />
        </svg>

    )
}
