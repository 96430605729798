import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import LogoSvg from '../SVGs/Logo'
import { Squash as Hamburger } from 'hamburger-react'
const useStyles = makeStyles(theme => ({
    root: {
        padding: "10px 20px",
        boxShadow: '0px 4px 20px rgba(44, 13, 108, 0.2)'
    }
}))

export default function AppBarMobile({ openDrawer, setOpenDrawer }) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container style={{ flexWrap: 'nowrap' }} justifyContent='space-between' alignItems='center'>
                <Grid item>
                    <a href={'/'}>
                        <LogoSvg />
                    </a>
                </Grid>
                <Grid item>
                    {/* <IconButtonComponent onClick={() => setOpenDrawer(!openDrawer)}>
                        <MenuIcon />
                    </IconButtonComponent> */}
                    <Hamburger toggled={openDrawer} toggle={setOpenDrawer} color='rgba(0, 0, 0, 0.54)' size={22} />
                </Grid>
            </Grid>
        </div>
    )
}
